
  import { defineComponent, ref } from 'vue';
  import { onClickOutside } from '@vueuse/core'
  export default defineComponent({
    name : 'StudyTab',
    props:{
      num: [String, Number],
      text: String,
      showAddIcon:{
        type: Boolean,
        default: false
      },
      showMenuDots:{
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      },
      isUx: {
        type: Boolean,
        default: false
      }
    },
    methods:{
      openOptionsMenu(){
        this.showOptionsMenu = true
      },
      closeOptionsMenu(){
        this.showOptionsMenu = false
      },
      duplicate(){
        this.$emit('duplicate-task')
        this.closeOptionsMenu()
      },
      remove(){
        this.$emit('remove-task')
        this.closeOptionsMenu()
      }
    },
    setup(){
      const Menu = ref(null)
      const showOptionsMenu = ref(false)
      onClickOutside(Menu, (event) => showOptionsMenu.value = false)
      return { Menu, showOptionsMenu }
    }
  })
  