var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import vueApp from '@/main';
import store from '@/store';
import { defineComponent, inject } from 'vue';
import { mapGetters } from 'vuex';
import { mount } from '@/utils/mount';
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue';
import SwitchSelector from '~/components/SwitchSelector/SwitchSelector.vue';
import RangeSlider from '~/components/RangeSlider/RangeSlider.vue';
import Separation from '@/components/StudyCreation/Separation.vue';
import Button from '@/components/Button.vue';
import QuestionSurvey from '~/components/SurveyCreation/QuestionSurvey.vue';
import StudyTab from './StudyTab.vue';
import StudyFigmaUrl from './StudyFigmaUrl.vue';
import DeviceRecordSelector from './DeviceRecordSelector.vue';
import ClickTestConfig from './ClickTestConfig.vue';
import draggable from 'vuedraggable';
import { taskTypes } from '@/utils/constants';
import MediaViewer from "~/components/MediaViewer";
import Spinner from '@/components/Spinner.vue';
import { addRule, duplicateQuestion, addOptionMatrix, addValue, reorderQuestions, canRemoveQuestion, removeQuestionValueOption, removeQuestionValue } from '~/utils/survey-creation.ts';
export default defineComponent({
    name: 'StudyTask',
    components: {
        SuiteInput: SuiteInput,
        Separation: Separation,
        SwitchSelector: SwitchSelector,
        StudyTab: StudyTab,
        StudyFigmaUrl: StudyFigmaUrl,
        RangeSlider: RangeSlider,
        QuestionSurvey: QuestionSurvey,
        draggable: draggable,
        DeviceRecordSelector: DeviceRecordSelector,
        Button: Button,
        ClickTestConfig: ClickTestConfig,
        MediaViewer: MediaViewer,
        Spinner: Spinner
    },
    props: {
        task: {
            type: Object,
            default: function () { }
        },
        navigationRules: {
            type: Array,
            default: function () { return []; }
        },
        type: String,
        isUxTest: {
            type: Boolean,
            default: false
        }
    },
    computed: __assign(__assign({}, mapGetters([
        'studyPlatformType'
    ])), { selectedTab: function () {
            return this.platformTypeAll || this.platformTypeDesktop ? 0 : 1;
        }, platformTypeMobile: function () {
            return this.studyPlatformType === "ONLY_MOBILE";
        }, platformTypeDesktop: function () {
            return this.studyPlatformType === "ONLY_DESKTOP";
        }, platformTypeAll: function () {
            return this.studyPlatformType === "ALL_PLATFORMS";
        }, dragOptions: function () {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }, hasClickTestImage: function () {
            var task = this.task;
            if (task.clickTestData == undefined || task.clickTestData == null)
                return false;
            return task.clickTestData.imageUrl != null && task.clickTestData.imageUrl != undefined && task.clickTestData.imageUrl.length > 0;
        } }),
    watch: {
        'task.uxData.initialUrl': function () {
            if (this.task.uxData && this.task.uxData.initialUrl !== undefined) {
                this.checkValidUrl();
            }
        },
        'task.uxData.initialMobileUrl': function () {
            if (this.task.uxData && this.task.uxData.initialMobileUrl !== undefined) {
                this.checkValidUrl();
            }
        },
        'task.uxData.onlyRecord': function (newValue) {
            if (newValue === true) {
                delete this.task.uxData.checkpoints;
                this.dataIsUpdated();
            }
        },
    },
    data: function () {
        return {
            selectedTab: 0,
            enabledMinTime: false,
            messageCorrectUrl: this.$t('suite_study_figma_success'),
            messageErrorUrl: this.$t('suite_study_figma_error'),
            uxUrlInitialError: false,
            uxUrlInitialSuccess: false,
            uxUrlInitialMobileError: false,
            uxUrlInitialMobileSuccess: false,
            destroyClickTestInstance: function () { },
            imageHTML: new Image(),
            taskTypes: taskTypes,
            videoUploading: false,
            clickTestQuestionError: false
        };
    },
    methods: {
        onUxQuantiTaskChange: function (newValue) {
            var updatedTask;
            if (!newValue) {
                updatedTask = __assign(__assign({}, this.task), { uxQuantiTask: newValue, uxQuantiData: {
                        desktopStartUrl: '',
                        desktopSuccessUrls: [{ name: '', url: '' }],
                        mobileStartUrl: '',
                        mobileSuccessUrls: [{ name: '', url: '' }],
                        maxTime: 1,
                        minTime: 1,
                    } });
                updatedTask.taskType = "ONLY_QUESTIONS";
            }
            else {
                updatedTask = __assign(__assign({}, this.task), { uxQuantiTask: newValue });
                updatedTask.taskType = "UX_QUANTI";
            }
            this.$emit('update-task', updatedTask);
            this.uxQuantiTaskChange();
        },
        onUxTaskChange: function (newValue) {
            var updatedTask;
            if (!newValue) {
                updatedTask = __assign(__assign({}, this.task), { uxTask: newValue, uxData: undefined });
                updatedTask.taskType = "ONLY_QUESTIONS";
            }
            else {
                updatedTask = __assign(__assign({}, this.task), { uxTask: newValue, uxData: {
                        initialUrl: '',
                        initialMobileUrl: '',
                        recordScreen: false,
                        recordMicrophone: false,
                        recordWebcam: false,
                        checkpoints: [],
                        onlyRecord: false,
                    } });
                updatedTask.taskType = "UX_VIDEO";
            }
            this.$emit('update-task', updatedTask);
            this.dataIsUpdated();
        },
        onClickTestTaskChange: function (newValue) {
            var updatedTask;
            if (!newValue) {
                updatedTask = __assign(__assign({}, this.task), { clickTestTask: newValue, clickTestData: undefined });
                updatedTask.taskType = "ONLY_QUESTIONS";
            }
            else {
                updatedTask = __assign(__assign({}, this.task), { clickTestTask: newValue, clickTestData: {
                        imageUrl: '',
                        timeoutSeconds: 0,
                        message: '',
                        successZones: [{
                                successZoneTL_X: 0,
                                successZoneTL_Y: 0,
                                successZoneBR_X: 0,
                                successZoneBR_Y: 0
                            }]
                    } });
                updatedTask.taskType = "CLICK_TEST";
            }
            this.$emit('update-task', updatedTask);
            this.uxClickTestTaskChange();
        },
        // onWatNeuroTaskTaskChange(newValue: any) {
        //   let updatedTask;
        //   if (!newValue) {
        //     updatedTask = { ...this.task, watNeuroTask: newValue, neuroData: {} } as any; // neuroData asumiendo que existe
        //     updatedTask.taskType = "ONLY_QUESTIONS";
        //   } else {
        //     updatedTask = { ...this.task, watNeuroTask: newValue } as any;
        // updatedTask.taskType = "NEURO_IMAGE";
        //   }
        //   this.$emit('update-task', updatedTask);
        //   this.watNeuroTaskTaskChange();
        // },
        openClickTestConfigInstance: function () {
            var _a = mount(ClickTestConfig, {
                props: {
                    clickTestData: this.task.clickTestData,
                    taskNum: this.task.taskNum
                },
                app: vueApp
            }), el = _a.el, vNode = _a.vNode, destroy = _a.destroy;
            var app = vueApp;
            app._container.appendChild(el);
            this.destroyClickTestInstance = destroy;
        },
        copyGTMCode: function () {
            var code = document.getElementById("GTM_CODE");
            if (!code)
                return;
            var range = document.createRange();
            range.selectNodeContents(code);
            var sel = window.getSelection();
            if (!sel)
                return;
            sel.removeAllRanges();
            sel.addRange(range);
            document.execCommand("copy");
            sel.removeAllRanges();
            $(".copy-code-message").show();
            setTimeout(function () {
                $(".copy-code-message").hide();
            }, 2000);
        },
        checkValidUrl: function () {
            if (!this.task || !this.task.uxData) {
                return;
            }
            var pattern = new RegExp('^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?' +
                '(\\/[-a-z\\d%_.~+()]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$', 'i');
            if (this.platformTypeAll || this.platformTypeDesktop) {
                if (this.task.uxData.initialUrl) {
                    var url = this.task.uxData.initialUrl;
                    var isValidUrl = !!(pattern.test(url) && url.includes('http'));
                    this.uxUrlInitialError = !isValidUrl;
                    this.uxUrlInitialSuccess = isValidUrl;
                }
            }
            if (this.platformTypeAll || this.platformTypeMobile) {
                if (this.task.uxData.initialMobileUrl) {
                    var mobileUrl = this.task.uxData.initialMobileUrl;
                    var isValidMobileUrl = !!(pattern.test(mobileUrl) && mobileUrl.includes('http'));
                    this.uxUrlInitialMobileError = !isValidMobileUrl;
                    this.uxUrlInitialMobileSuccess = isValidMobileUrl;
                }
            }
        },
        updateDesktopUrls: function (event) {
            this.task.uxQuantiData.desktopStartUrl = event.startUrl;
            this.task.uxQuantiData.desktopSuccessUrls[0] = { name: '', url: event.successUrl };
            this.dataIsUpdated();
        },
        updateMobileUrls: function (event) {
            this.task.uxQuantiData.mobileStartUrl = event.startUrl;
            this.task.uxQuantiData.mobileSuccessUrls[0] = { name: '', url: event.successUrl };
            this.dataIsUpdated();
        },
        updateRecordDevices: function (devices) {
            this.task.uxData.recordScreen = devices.recordScreen;
            this.task.uxData.recordMicrophone = devices.recordMicrophone;
            this.task.uxData.recordWebcam = devices.recordWebcam;
        },
        getDesktopUrl: function (task) {
            if (task && task.uxQuantiData && task.uxQuantiData.desktopSuccessUrls && task.uxQuantiData.desktopSuccessUrls.length > 0) {
                return task.uxQuantiData.desktopSuccessUrls[0].url;
            }
            else {
                this.task.uxQuantiData = {
                    desktopStartUrl: '',
                    desktopSuccessUrls: [],
                    mobileStartUrl: '',
                    mobileSuccessUrls: [],
                    maxTime: 1,
                    minTime: 1
                };
                task.uxQuantiData.desktopSuccessUrls[0] = { name: '', url: '' };
                return task.uxQuantiData.desktopSuccessUrls[0].url;
            }
        },
        getMobileUrl: function (task) {
            if (task && task.uxQuantiData && task.uxQuantiData.mobileSuccessUrls && task.uxQuantiData.mobileSuccessUrls.length > 0) {
                return task.uxQuantiData.mobileSuccessUrls[0].url;
            }
            else {
                this.task.uxQuantiData = {
                    desktopStartUrl: '',
                    desktopSuccessUrls: [],
                    mobileStartUrl: '',
                    mobileSuccessUrls: [],
                    maxTime: 1,
                    minTime: 1
                };
                task.uxQuantiData.mobileSuccessUrls[0] = { name: '', url: '' };
                return task.uxQuantiData.mobileSuccessUrls[0].url;
            }
        },
        uxQuantiTaskChange: function () {
            if (!this.task.uxQuantiTask && this.task.uxQuantiData == undefined) {
                this.task.uxQuantiData = {
                    desktopStartUrl: '',
                    desktopSuccessUrls: [],
                    mobileStartUrl: '',
                    mobileSuccessUrls: [],
                    maxTime: 1,
                    minTime: 1
                };
            }
            this.dataIsUpdated();
        },
        uxTaskChange: function () {
            if (this.task.uxTask && this.task.uxData == undefined) {
                this.task.uxData = {
                    initialUrl: '',
                    initialMobileUrl: '',
                    recordScreen: false,
                    recordMicrophone: false,
                    recordWebcam: false,
                    checkpoints: []
                };
            }
            this.dataIsUpdated();
        },
        uxClickTestTaskChange: function () {
            if (this.task.clickTestTask && this.task.clickTestData == undefined) {
                this.task.clickTestData = {
                    imageUrl: '',
                    timeoutSeconds: 0,
                    message: '',
                    successZones: [{
                            successZoneTL_X: 0,
                            successZoneTL_Y: 0,
                            successZoneBR_X: 0,
                            successZoneBR_Y: 0
                        }]
                };
            }
            this.dataIsUpdated();
        },
        changeRule: function () {
            this.dataIsUpdated();
        },
        addRule: function (question) {
            addRule(this.navigationRules, question, this.task.taskNum);
            this.dataIsUpdated();
        },
        duplicateQuestion: function (question) {
            duplicateQuestion(question, this.task.questions);
            this.dataIsUpdated();
        },
        canRemoveQuestion: function (questionIndex) {
            canRemoveQuestion(questionIndex, this.task.questions, this.navigationRules, this.$t);
            this.dataIsUpdated();
        },
        removeQuestionValue: function (event, index) {
            removeQuestionValue(this.task.questions, index, event);
            this.dataIsUpdated();
        },
        removeQuestionValueOption: function (event, index) {
            removeQuestionValueOption(this.task.questions, index, event);
            this.dataIsUpdated();
        },
        reorderQuestions: function () {
            reorderQuestions(this.task.questions);
            this.dataIsUpdated();
        },
        addValue: function (question, addTxt) {
            addValue(question, addTxt);
            this.dataIsUpdated();
        },
        addOptionMatrix: function (question) {
            addOptionMatrix(question);
            this.dataIsUpdated();
        },
        dataIsUpdated: function () {
            this.checkValidUrl();
            this.$emit('data-updated');
        },
        duplicateCheckpoint: function (checkpoint) {
            var cp = checkpoint;
            this.task.uxData.checkpoints.push(cp);
            this.dataIsUpdated();
        },
        deleteCheckpoint: function (index) {
            this.task.uxData.checkpoints.splice(index, 1);
            this.dataIsUpdated();
        },
        hasErrors: function () {
            if (this.task.name == undefined || this.task.name.length < 3)
                return true;
            if (this.task.description == undefined || this.task.description.length < 3)
                return true;
            if (this.task.uxQuantiTask) {
                var figmaDesktop = this.$refs.FigmaDesktop;
                var figmaMobile = this.$refs.FigmaMobile;
                if (this.platformTypeAll || this.platformTypeDesktop && !this.platformTypeMobile) {
                    if (figmaDesktop.hashErrors())
                        return true;
                }
                if (this.platformTypeAll || this.platformTypeMobile && !this.platformTypeDesktop) {
                    if (figmaMobile.hashErrors())
                        return true;
                }
            }
            if (this.task.uxTask) {
                if (!this.task.uxData.recordWebcam && !this.task.uxData.recordMicrophone && !this.task.uxData.recordWebcam)
                    return true;
                if ((this.platformTypeAll || this.platformTypeDesktop) && (!this.task.uxData.initialUrl || this.task.uxData.initialUrl.length == 0))
                    return true;
                if ((this.platformTypeAll || this.platformTypeMobile) && (!this.task.uxData.initialMobileUrl || this.task.uxData.initialMobileUrl.length == 0))
                    return true;
                if (this.uxUrlInitialError || this.uxUrlInitialMobileError)
                    return true;
                if (!this.task.uxData.onlyRecord) {
                    if (!this.task.uxData.checkpoints || this.task.uxData.checkpoints.length == 0)
                        return true;
                }
            }
            if (this.task.watNeuroTask) {
                if (this.task.video == null || this.task.video.length < 5)
                    return true;
            }
            if (!this.task.uxQuantiTask && !this.task.uxTask && !this.task.clickTestTask && !this.task.watNeuroTask && this.task.questions.length == 0)
                return true;
            if (this.task.taskType == "CLICK_TEST" && !this.task.questions.length) {
                this.clickTestQuestionError = true;
                return true;
            }
            if (this.task.questions.length > 0) {
                var error = false;
                for (var index in this.task.questions) {
                    var question = this.$refs['Question-' + index];
                    if (question.hasErrors())
                        error = true;
                }
                if (error)
                    return true;
            }
            this.clickTestQuestionError = false;
            return false;
        },
        loadCanvasData: function () {
            var canvas = document.getElementById('Canvas-Task-' + this.task.taskNum);
            var parentContainer = document.getElementById('CanvasParent-Task-' + this.task.taskNum);
            var ctx = canvas.getContext('2d');
            var imageWidth = this.imageHTML.width;
            var imageHeight = this.imageHTML.height;
            var parentWidth = parentContainer.clientWidth;
            var parentHeight = parentContainer.clientHeight;
            var scaleWidth = parentWidth / imageWidth;
            var scaleHeight = parentHeight / imageHeight;
            var scaleFactor = Math.min(scaleWidth, scaleHeight);
            if (scaleFactor < 1) {
                imageWidth = imageWidth * scaleFactor;
                imageHeight = imageHeight * scaleFactor;
            }
            canvas.width = imageWidth;
            canvas.height = imageHeight;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(this.imageHTML, 0, 0, canvas.width, canvas.height);
            for (var _i = 0, _a = this.task.clickTestData.successZones; _i < _a.length; _i++) {
                var coord = _a[_i];
                ctx.fillStyle = 'rgba(129, 52, 225, 0.43)';
                ctx.strokeStyle = 'rgb(129, 52, 225)';
                ctx.lineWidth = 3;
                var startX = coord.successZoneTL_X * canvas.width;
                var startY = coord.successZoneTL_Y * canvas.height;
                var endX = coord.successZoneBR_X * canvas.width;
                var endY = coord.successZoneBR_Y * canvas.height;
                ctx.fillRect(startX, startY, endX - startX, endY - startY);
                ctx.strokeRect(startX, startY, endX - startX, endY - startY);
                var additionalRectText = this.$t('suite_study_click_area');
                ctx.fillStyle = 'rgb(129, 52, 225)';
                ctx.fillRect(startX - 2, startY - 22, 105, 22);
                ctx.fillStyle = 'white';
                ctx.font = '12px Arial';
                ctx.fillText(additionalRectText, startX + 5, startY - 8);
            }
        },
        generateCanvas: function () {
            var vm = this;
            var imageUrl = this.task.clickTestData.imageUrl;
            this.imageHTML = new Image();
            this.imageHTML.src = imageUrl;
            this.imageHTML.onload = function () {
                vm.loadCanvasData();
            };
        },
        uploadVideoForNeuro: function () {
            return __awaiter(this, void 0, void 0, function () {
                var files;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.videoUploading = true;
                            this.task.video = "";
                            files = document.getElementById('file-neuro').files;
                            if (files == null || files.length == 0)
                                return [2 /*return*/];
                            return [4 /*yield*/, store.dispatch('uploadVideo', files != undefined ? files[0] : null)
                                    .then(function (data) {
                                    _this.task.video = data.path;
                                    _this.videoUploading = false;
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    mounted: function () {
        var _this = this;
        var emitter = inject("emitter");
        emitter.on("save-clicktest-data", function (payload) {
            if (payload.taskNum !== _this.task.taskNum)
                return;
            _this.destroyClickTestInstance();
            _this.task.clickTestData = payload.clickTestData;
            _this.generateCanvas();
            _this.dataIsUpdated();
        });
        if (this.hasClickTestImage)
            this.generateCanvas();
        this.checkValidUrl();
    }
});
