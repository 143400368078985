import { defineComponent } from 'vue';
import Button from '@/components/Button.vue';
import Input from '~/components/Input/Input.vue';
import InputTextarea from '@/components/StudyCreation/InputTextarea.vue';
export default defineComponent({
    name: 'AddCheckpointModal',
    components: {
        Button: Button,
        Input: Input,
        InputTextarea: InputTextarea
    },
    computed: {
        formEnabled: function () {
            var checkpoint = this.checkpoint;
            return checkpoint.type != '' && checkpoint.name.length > 0 && checkpoint.message.length > 0 && (checkpoint.elementSelector.length > 0 || checkpoint.checkUrl.length > 0);
        }
    },
    data: function () {
        return {
            checkpoint: {
                type: '',
                name: '',
                message: '',
                elementSelector: '',
                checkUrl: ''
            },
            created: false,
        };
    },
    methods: {
        close: function () {
            var vm = this;
            this.created = false;
            setTimeout(function () {
                vm.$emit('close');
            }, 200);
        },
        add: function () {
            var checkpoint = {
                type: this.checkpoint.type,
                name: this.checkpoint.name,
                message: this.checkpoint.message,
                elementSelector: this.checkpoint.type == 'URL_LOAD' ? null : this.checkpoint.elementSelector,
                checkUrl: this.checkpoint.type != 'URL_LOAD' ? null : this.checkpoint.checkUrl
            };
            this.$emit('add-checkpoint', checkpoint);
            this.close();
        }
    },
    mounted: function () {
        this.created = true;
    }
});
