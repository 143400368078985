
import { defineComponent, inject } from 'vue';
import Button from '../Button.vue'
import store from '@/store'
export default defineComponent({
  name : 'ClickTestConfig',
  components:{
    Button
  },
  props:{
    clickTestData: {
      type: Object,
      default: () => ({ imageUrl: '' }),
    },
    taskNum: {
      type: [Number, String],
    }     
  },
  data(){
    return{
      firstLoad: true,
      drawing: false,
      selected:0,
      startX:0,
      startY:0,
      endX:0,
      endY:0,
      coordsPercent:[{
        startX:0,
        startY:0,
        endX:0,
        endY:0
      }],
      imageHTML: new Image(),
      imageUrl: '',
      loading: false
    }
  },
  methods:{
    changeMenuSelection(id: any){
      this.selected = id
    },
    generateCanvas(){
      let vm = this
      
      const imageUrl = this.imageUrl;
      this.imageHTML = new Image();
      this.imageHTML.src = imageUrl;

      this.imageHTML.onload = function() {
        vm.loadCanvasData()
      };

      window.addEventListener('resize', function() {
        if(!vm.firstLoad) {
          vm.setCoordsToPx()
          vm.loadCanvasData()
        }
      });
    },
    loadCanvasData() {
      const canvas: any = document.getElementById('ClickTestConfigCanvas');
      const parentContainer: any = document.getElementById('ClickTestConfigContent');
      const ctx: any = canvas.getContext('2d');
      const backgroundColor = 'rgba(129, 52, 225, 0.43)';
      const borderColor = 'rgb(129, 52, 225)';
      const fontColor = 'white';
      const fontType = '12px Arial';

      let imageWidth = this.imageHTML.width * 0.96;
      let imageHeight = this.imageHTML.height * 0.96;

      let parentWidth = (parentContainer.clientWidth * 0.96) - 84;
      let parentHeight = (parentContainer.clientHeight * 0.96) - 64;

      const scaleWidth = parentWidth / imageWidth;
      const scaleHeight = parentHeight / imageHeight;
      const scaleFactor = Math.min(scaleWidth, scaleHeight);

      if(scaleFactor < 1) {
        imageWidth = imageWidth * scaleFactor
        imageHeight = imageHeight * scaleFactor
      }

      canvas.width = imageWidth
      canvas.height = imageHeight

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(this.imageHTML, 0, 0, canvas.width, canvas.height);

      if(this.firstLoad) this.setCoordsToPx()
      this.firstLoad = false

      const additionalRectText = this.$t('suite_study_click_area');
      for(let coords of this.coordsPercent) {
        ctx.fillStyle = backgroundColor;
        ctx.strokeStyle = borderColor;
        ctx.lineWidth = 3;

        ctx.fillRect(coords.startX, coords.startY, coords.endX - coords.startX, coords.endY - coords.startY);
        ctx.strokeRect(coords.startX, coords.startY, coords.endX - coords.startX, coords.endY - coords.startY);

        ctx.fillStyle = borderColor;
        ctx.fillRect(coords.startX - 2, coords.startY - 22, 105, 22);

        ctx.fillStyle = fontColor;
        ctx.font = fontType;
        ctx.fillText(additionalRectText, coords.startX + 5, coords.startY - 8);
      }

      ctx.fillRect(this.startX, this.startY, this.endX - this.startX, this.endY - this.startY);
      ctx.strokeRect(this.startX, this.startY, this.endX - this.startX, this.endY - this.startY);

      ctx.fillStyle = borderColor;
      ctx.fillRect(this.startX - 2, this.startY - 22, 105, 22);

      ctx.fillStyle = fontColor;
      ctx.font = fontType;
      ctx.fillText(additionalRectText, this.startX + 5, this.startY - 8);

      ctx.fillStyle = backgroundColor;
      ctx.strokeStyle = borderColor;
      ctx.lineWidth = 3;
      
    },
    startDrawing(event: any) {
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      this.drawing = true;
      const rect = canvas.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
    },
    async drawRectangle(event: any) {
      if (!this.drawing) return;
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      const rect = canvas.getBoundingClientRect();
      this.endX = event.clientX - rect.left;
      this.endY = event.clientY - rect.top;
      this.loadCanvasData()
    },
    async stopDrawing() {
      this.drawing = false;
      await this.addDrawedCoords();
    },
    forceUploadImage(){
      const input :any = this.$refs.fileInput;
      input.click();
    },
    handleFileSelect(event: any){
      this.loading = true
      const file = event.target.files[0];
      store.dispatch('uploadFile', file != undefined ? file : null)
      .then((data) => {
        this.imageUrl = data.path
        this.removeCoords()
      })
      this.loading = false
    },
    setCoordsToPx(){
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      this.coordsPercent.forEach((coord, index) => {
        this.coordsPercent[index].startX = coord.startX * canvas.width;
        this.coordsPercent[index].startY = coord.startY * canvas.height;
        this.coordsPercent[index].endX = coord.endX * canvas.width;
        this.coordsPercent[index].endY = coord.endY * canvas.height;
      });
    },
    setCoordsInitial(){
      let index = 0
      for(let successZone of this.clickTestData.successZones) {
        index++
        if (index == 1) {
          this.coordsPercent[0].startX = successZone.successZoneTL_X
          this.coordsPercent[0].startY = successZone.successZoneTL_Y
          this.coordsPercent[0].endX = successZone.successZoneBR_X
          this.coordsPercent[0].endY = successZone.successZoneBR_Y
        } else {
          this.coordsPercent.push({
            startX:successZone.successZoneTL_X,
            startY:successZone.successZoneTL_Y,
            endX:successZone.successZoneBR_X,
            endY:successZone.successZoneBR_Y
          })
        }
      }
    },
    addDrawedCoords(){
      let rectNew ={
        startX:this.startX,
        startY:this.startY,
        endX:this.endX,
        endY:this.endY
      }

      if (rectNew.startX > rectNew.endX){
        rectNew.startX = this.endX
        rectNew.endX = this.startX
      }

      if (rectNew.startY > rectNew.endY){
        rectNew.startY = this.endY
        rectNew.endY = this.startY
      }

      if (this.coordsPercent[0].startX == 0 && this.coordsPercent[0].startY == 0 &&
          this.coordsPercent[0].endX == 0 && this.coordsPercent[0].endY == 0) {
        this.coordsPercent[0].startX = rectNew.startX;
        this.coordsPercent[0].startY = rectNew.startY;
        this.coordsPercent[0].endX = rectNew.endX;
        this.coordsPercent[0].endY = rectNew.endY;
      } else {
        this.coordsPercent.push({
          startX: rectNew.startX,
          startY: rectNew.startY,
          endX: rectNew.endX,
          endY: rectNew.endY
        })
      }
    },
    removeCoords(){
      this.startX = 0
      this.startY = 0
      this.endX = 0
      this.endY = 0
      this.coordsPercent = [{
        startX:0,
        startY:0,
        endX:0,
        endY:0
      }]
      this.generateCanvas()
    },
  },
  mounted(){
    this.$nextTick(() => {
      if(this.clickTestData.imageUrl == null || this.clickTestData.imageUrl == undefined || this.clickTestData.imageUrl.length == 0){
        window.dispatchEvent(new Event('resize'));
        document.getElementById('fileInput')?.addEventListener('change', this.handleFileSelect);
        this.forceUploadImage()
        return
      } 
      this.imageUrl = this.clickTestData.imageUrl
      this.setCoordsInitial()
      this.generateCanvas();
      window.dispatchEvent(new Event('resize'));
      document.getElementById('fileInput')?.addEventListener('change', this.handleFileSelect);
    });
  },
  setup (props) {
    const emitter: any = inject("emitter")

    const save = (data: any, imageUrl: any) => {
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      let clickTestData: any = props.clickTestData
      clickTestData.imageUrl = imageUrl
      clickTestData.successZones = []
      data.forEach((zone: any, index: any) => {
        let zoneTL_X = zone.startX / canvas.width;
        let zoneTL_Y = zone.startY / canvas.height;
        let zoneBR_X = zone.endX / canvas.width;
        let zoneBR_Y = zone.endY / canvas.height;
        clickTestData.successZones.push({
            successZoneTL_X: zoneTL_X,
            successZoneTL_Y: zoneTL_Y,
            successZoneBR_X: zoneBR_X,
            successZoneBR_Y: zoneBR_Y
          })
      })
      const payload = {
        clickTestData,
        taskNum: props.taskNum
      }

      emitter.emit("save-clicktest-data", payload)
    }

    return {
        save
    }
  }
})
