var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Swal from 'sweetalert2';
var ruleActions = ["jump-to", "killer", "finish-test", "quality-killer", "cond-show", "cond-show-option", "quality-killer-nosanction"];
export var formatNavigationRules = function (navigationRules, isActivity) {
    var rulesFormated = [];
    for (var _i = 0, navigationRules_1 = navigationRules; _i < navigationRules_1.length; _i++) {
        var r = navigationRules_1[_i];
        var rule = "if ";
        for (var _a = 0, _b = r.conditions; _a < _b.length; _a++) {
            var c = _b[_a];
            if (c.optionId == null || c.optionId == undefined || c.optionId == "" && c.optionId != 0)
                rule += "task" + c.taskNum + "-question" + c.qId + c.equality + c.valId;
            else
                rule += "task" + c.taskNum + "-question" + c.qId + "-option" + c.optionId + c.equality + c.valId;
            if (c.operator != null)
                rule += c.operator;
        }
        rule += " then " + r.action.type;
        if (r.action.type == "jump-to" || r.action.type == "cond-show")
            rule += " task" + r.action.taskNum + "-question" + r.action.qId;
        if (r.action.type == "cond-show-option")
            rule += " task" + r.action.taskNum + "-question" + r.action.qId + "-option" + r.action.valId;
        if (isActivity)
            rule = inspectNavigationRule(rule);
        rulesFormated.push(rule);
    }
    return rulesFormated;
};
export var navigationRulesHasErrors = function (navigationRules, withErrorDetail) {
    var rules = navigationRules;
    var errors = [];
    var errorDetail = [];
    for (var i in rules) {
        var ruleErrorDetail = {
            qId: rules[i].conditions[0].qId,
            taskNum: rules[i].conditions[0].taskNum,
            error: false
        };
        if ((rules[i].action.type == "jump-to" || rules[i].action.type == "cond-show" || rules[i].action.type == "cond-show-option") && rules[i].action.qId == null) {
            errors.push("rulequestion-" + i);
            ruleErrorDetail.error = true;
        }
        if (rules[i].action.type == "cond-show-option" && rules[i].action.valId == null) {
            errors.push("ruleval-" + i);
            ruleErrorDetail.error = true;
        }
        for (var i2 in rules[i].conditions) {
            if (rules[i].conditions[i2].valId == null || (rules[i].conditions[i2].equality == "#" && rules[i].conditions[i2].valId == 0)) {
                errors.push("rulecondition-" + i + "-" + i2);
                ruleErrorDetail.error = true;
            }
        }
        if (ruleErrorDetail.error)
            errorDetail.push(ruleErrorDetail);
    }
    if (withErrorDetail)
        return { errorDetail: errorDetail, error: errors.length > 0 };
    if (errors.length > 0)
        return true;
};
export var getQuestionMaxId = function (questions) {
    var id = 0;
    for (var _i = 0, questions_1 = questions; _i < questions_1.length; _i++) {
        var q = questions_1[_i];
        if (parseInt(q.id) > id)
            id = parseInt(q.id);
    }
    return id + 1;
};
export var getQuestionValueMaxId = function (values) {
    var id = 0;
    for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
        var q = values_1[_i];
        if (parseInt(q.id) > id)
            id = parseInt(q.id);
    }
    return id + 1;
};
export var isQuestionWithValues = function (type) {
    return type == 'SELECTION' || type == 'MULTISELECTION' || type == 'SORTING' || type == 'DIVIDE_POINTS' || type == 'MATRIX' || type == 'IMAGE_CHOICE' || type == 'MULTIPLEIMAGE_CHOICE';
};
export var isQuestionText = function (type) {
    return type == 'TEXT' || type == 'MULTITEXT' || type == 'TEXTAREA';
};
export var addRule = function (navigationRules, question, taskNum) {
    navigationRules.push({
        conditions: [
            { equality: "=", operator: null, taskNum: taskNum ? taskNum : 1, qId: question.id, valId: 0 }
        ],
        action: {
            type: "jump-to", taskNum: taskNum ? taskNum : 1, qId: null
        }
    });
};
export var removeRule = function (navigationRules, index) {
    navigationRules.splice(index, 1);
};
export var generateNavigationRules = function (navigationRules, data) {
    for (var i = 0; i < data.length; i++) {
        var condition = data[i].split("then")[0].replace("if", "");
        var action = data[i].split("then")[1];
        var conditionCount = (condition.match(/task/g) || []).length;
        navigationRules[i] = {};
        navigationRules[i].conditions = [];
        navigationRules[i].action = ruleActionToJson(action);
        if (conditionCount > 1) {
            for (var x = 0; x < conditionCount; x++) {
                if (x == conditionCount - 1) {
                    var values = condition.match(/\d+/g);
                    var equality = getRuleEquality(condition);
                    if (values.length > 3)
                        navigationRules[i].conditions.push({ operator: null, taskNum: values[0], equality: equality, qId: values[1], optionId: values[2], valId: values[3] });
                    else
                        navigationRules[i].conditions.push({ operator: null, taskNum: values[0], equality: equality, qId: values[1], valId: values[2] });
                }
                else {
                    var andOperator = condition.indexOf("&");
                    var orOperator = condition.indexOf("|");
                    var operator = "";
                    var operatorPos = "";
                    if (andOperator == -1) {
                        operator = "|";
                        operatorPos = orOperator;
                    }
                    else if (orOperator == -1) {
                        operator = "&";
                        operatorPos = andOperator;
                    }
                    else if (andOperator > orOperator) {
                        operator = "|";
                        operatorPos = orOperator;
                    }
                    else {
                        operator = "&";
                        operatorPos = andOperator;
                    }
                    var values = condition.substr(0, operatorPos).match(/\d+/g);
                    var equality = getRuleEquality(condition.substr(0, operatorPos));
                    condition = condition.substr(operatorPos).replace(operator, "");
                    if (values.length > 3)
                        navigationRules[i].conditions.push({ operator: operator, taskNum: values[0], equality: equality, qId: values[1], optionId: values[2], valId: values[3] });
                    else
                        navigationRules[i].conditions.push({ operator: operator, taskNum: values[0], equality: equality, qId: values[1], valId: values[2] });
                }
            }
        }
        else {
            var values = condition.match(/\d+/g);
            var equality = getRuleEquality(condition);
            if (values.length > 3)
                navigationRules[i].conditions.push({ operator: null, taskNum: values[0], equality: equality, qId: values[1], optionId: values[2], valId: values[3] });
            else
                navigationRules[i].conditions.push({ operator: null, taskNum: values[0], equality: equality, qId: values[1], valId: values[2] });
        }
    }
};
var ruleActionToJson = function (action) {
    var actionReturn = { type: "", taskNum: "", qId: "", valId: null };
    for (var i = 0; i < ruleActions.length; i++) {
        if (action.indexOf(ruleActions[i]) != -1) {
            actionReturn.type = ruleActions[i];
            if (actionReturn.type != ruleActions[1] && actionReturn.type != ruleActions[2] && actionReturn.type != ruleActions[3] && actionReturn.type != ruleActions[6]) {
                var matchNumbers = action.match(/\d+/g);
                actionReturn.taskNum = matchNumbers[0];
                actionReturn.qId = matchNumbers[1];
                if (ruleActions[i] == "cond-show-option")
                    actionReturn.valId = matchNumbers[2];
            }
        }
    }
    return actionReturn;
};
var getRuleEquality = function (condition) {
    var equality = "";
    if (condition.indexOf("==") != -1)
        equality = "==";
    else if (condition.indexOf("!=") != -1)
        equality = "!=";
    else if (condition.indexOf("#") != -1)
        equality = "#";
    else
        equality = "=";
    return equality;
};
export var createQuestion = function (questions, type) {
    var question = {
        type: type.type,
        text: '',
    };
    if (question.type == 'MULTISELECTION')
        createMultiSelectionQuestion(question);
    if (question.type == 'SELECTION')
        addValue(question, false);
    if (question.type == 'RANKING')
        createRankingQuestion(question, type);
    if (question.type == 'RATING_FACES')
        createRatingFacesQuestion(question, type);
    if (question.type == 'MULTITEXT')
        createMultiTextQuestion(question);
    if (question.type == 'DIVIDE_POINTS')
        createDividePointsQuestion(question);
    if (question.type == 'MATRIX')
        createMatrixQuestion(question, type);
    if (question.type == 'MULTIPLEIMAGE_CHOICE' || question.type == 'IMAGE_CHOICE')
        addValue(question, false);
    addQuestion(question, questions);
};
export var duplicateQuestion = function (question, questions) {
    addQuestion(question, questions);
};
var addQuestion = function (question, questions) {
    var q = JSON.parse(JSON.stringify(question));
    q.id = getQuestionMaxId(questions),
        q.order = questions.length + 1,
        questions.push(q);
};
var createMultiSelectionQuestion = function (question) {
    question.multiselectionMax = 1;
    question.multiselectionMin = 1;
    addValue(question, false);
};
var createRankingQuestion = function (question, type) {
    question.rankingMin = 0;
    question.rankingMax = 0;
    question.rankingLeftLabel = null;
    question.rankingRightLabel = null;
    question.rankingType = type.name == 'RANKING-STARS' ? 'STARS' : 'NUMBERS';
};
var createRatingFacesQuestion = function (question, type) {
    question.rankingMin = 1;
    question.rankingMax = 5;
    question.rankingLeftLabel = null;
    question.rankingRightLabel = null;
    question.rankingType = type.name == 'RANKING-STARS' ? 'STARS' : 'NUMBERS';
};
var createMultiTextQuestion = function (question) {
    question.placeholders = "";
    question.textDescriptions = "";
    question.textNum = 1;
};
var createDividePointsQuestion = function (question) {
    question.pointsNum = null;
    addValue(question, false);
};
var createMatrixQuestion = function (question, type) {
    question.rankingMin = 0;
    question.rankingMax = 0;
    if (type.name == 'matrix-options') {
        question.matrixRadioButton = true;
        question.matrixVisualization = 'RADIO_BUTTONS';
    }
    addValue(question, false);
    addOptionMatrix(question);
};
export var addValue = function (question, addTxt) {
    var isImageChoiceQuestion = question.type == 'MULTIPLEIMAGE_CHOICE' || question.type == 'IMAGE_CHOICE';
    if (isImageChoiceQuestion && !question.imageValues)
        question.imageValues = [];
    if (!isImageChoiceQuestion && !question.values)
        question.values = [];
    var value = {
        id: isImageChoiceQuestion ? getQuestionValueMaxId(question.imageValues) : getQuestionValueMaxId(question.values),
        value: '',
        image: isImageChoiceQuestion ? '' : undefined,
        addtxt: addTxt
    };
    if (isImageChoiceQuestion)
        question.imageValues.push(value);
    else
        question.values.push(value);
};
export var addOptionMatrix = function (question) {
    if (!question.radioOptionTexts)
        question.radioOptionTexts = [];
    question.radioOptionTexts.push('');
};
export var reorderQuestions = function (questions) {
    questions = questions.map(function (q, index) {
        q.order = index + 1;
        return q;
    });
};
export var canRemoveQuestion = function (questionIndex, questions, navigationRules, translator) { return __awaiter(void 0, void 0, void 0, function () {
    var q, rules;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                q = questions[questionIndex];
                rules = getRulesIndexToRemove(q, navigationRules);
                if (!(rules.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, Swal.fire({
                        title: translator('msg_confirmation_delete_question'),
                        text: translator('msg_confirmation_delete_question_text'),
                        icon: 'warning',
                        cancelButtonText: translator('action_cancel'),
                        confirmButtonText: translator('msg_agree'),
                        showCancelButton: true,
                        customClass: {
                            popup: 'swal-customWarning'
                        }
                    }).then(function (result) {
                        if (result.isConfirmed) {
                            removeQuestion(questionIndex, questions);
                            for (var _i = 0, rules_1 = rules; _i < rules_1.length; _i++) {
                                var index = rules_1[_i];
                                navigationRules.splice(index, 1);
                            }
                        }
                    })];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                removeQuestion(questionIndex, questions);
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
var getRulesIndexToRemove = function (q, navigationRules) {
    var rulesIndex = [];
    var index = 0;
    for (var _i = 0, navigationRules_2 = navigationRules; _i < navigationRules_2.length; _i++) {
        var r = navigationRules_2[_i];
        if (r.action.qId == q.id)
            rulesIndex.unshift(index);
        for (var _a = 0, _b = r.conditions; _a < _b.length; _a++) {
            var c = _b[_a];
            if (c.qId == q.id && !rulesIndex.includes(index))
                rulesIndex.unshift(index);
        }
        index++;
    }
    return rulesIndex;
};
var removeQuestion = function (index, questions) {
    questions.splice(index, 1);
    reorderQuestions(questions);
};
export var removeQuestionValueOption = function (questions, index, optionIndex) {
    questions[index].radioOptionTexts.splice(optionIndex, 1);
};
export var removeQuestionValue = function (questions, index, valueIndex) {
    var isImageChoiceQuestion = questions[index].type == 'MULTIPLEIMAGE_CHOICE' || questions[index].type == 'IMAGE_CHOICE';
    if (!isImageChoiceQuestion)
        questions[index].values.splice(valueIndex, 1);
    else
        questions[index].imageValues.splice(valueIndex, 1);
};
export var addTask = function (tasks, taskType) {
    if (taskType === void 0) { taskType = false; }
    var task = {
        taskNum: tasks.length + 1,
        name: '',
        description: '',
        uxQuantiTask: taskType === 'watuxquanti',
        uxTask: taskType === 'watux',
        clickTestTask: taskType === 'clicktest',
        questions: [],
        uxQuantiData: {
            desktopStartUrl: '',
            desktopSuccessUrls: [{ name: '', url: '' }],
            mobileStartUrl: '',
            mobileSuccessUrls: [{ name: '', url: '' }],
            maxTime: 1,
            minTime: 1,
        },
        uxData: {
            initialUrl: '',
            initialMobileUrl: '',
            recordScreen: false,
            recordMicrophone: false,
            recordWebcam: false,
            checkpoints: [],
            onlyRecord: false,
        },
        clickTestData: {
            imageUrl: '',
            timeoutSeconds: 0,
            message: '',
            successZones: [{
                    successZoneTL_X: 0,
                    successZoneTL_Y: 0,
                    successZoneBR_X: 0,
                    successZoneBR_Y: 0
                }]
        },
    };
    tasks.push(task);
};
export var removeTask = function (tasks, index) {
    tasks.splice(index, 1);
    reorderTasks(tasks);
};
export var duplicateTask = function (tasks, task) {
    var newTask = JSON.parse(JSON.stringify(task));
    newTask.taskNum = tasks.length + 1;
    tasks.push(newTask);
};
export var reorderTasks = function (tasks) {
    tasks = tasks.map(function (t, index) {
        t.taskNum = index + 1;
        return t;
    });
};
export var inspectNavigationRule = function (rule) {
    var regex = /task(?!1)(\d*)-/g;
    return rule.replace(regex, 'task1-');
};
