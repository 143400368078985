
import { defineComponent } from 'vue';
import StudyTab from './StudyTab.vue'
import StudyTask from './StudyTask.vue'
import FigmaIframeModal from '../FigmaIframeModal.vue'
import AddQuestionModal from '@/components/StudyCreation/AddQuestionModal.vue'
import AddCheckpointModal from '@/components/StudyCreation/AddCheckpointModal.vue'
import { createQuestion } from '~/utils/survey-creation.ts'

export default defineComponent({
  name : 'StudyUXTasks',
  components:{
    StudyTab,
    StudyTask,
    FigmaIframeModal,
    AddQuestionModal,
    AddCheckpointModal
  },
  props:{
    tasks:{
      type: Array,
      default: () => []
    },
    navigationRules:{
      type: Array,
      default: () => []
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    type: String
  },
  data(){
    return{
      activeTaskIndex: 0,
      showIframe: false,
      iframeUrl: "",
      isModalOpen: false,
      taskIndexToAddQuestion:0,
      isModalCheckpointOpen: false,
      taskIndexToAddCheckpoint: 0,
      dragStartIndex: null as number|null,
      dragOverIndex: null as number|null
    }
  },
  computed:{
    activeTaskNum(): any {
      const tasks: any = this.tasks;
      if (this.tabIndex >= 0 && this.tabIndex < tasks.length) {
        return tasks[this.tabIndex].taskNum; 
      } else {
        return tasks.length > 0 ? tasks[tasks.length - 1].taskNum : null;
      }
    },
  },
  methods:{
    selectTask(index: any, taskNum:any = null){
      
      if (taskNum != null){
        const StudyTask:any = this.$refs['StudyTask-' + taskNum]
        
        if(StudyTask.hasClickTestImage){
          StudyTask.generateCanvas()
        }
      }
      this.$emit('update-tab-index', index);
    },
    removeTask(index: any){
      this.selectTask(0)
      this.$emit('remove-task', index)
    },
    openIframe(event: any){
      this.showIframe = true
      this.iframeUrl = event
    },
    hasErrors(){
      let errors = []
      for(let t of this.tasks){
        const task: any = t
        const StudyTask:any = this.$refs['StudyTask-' + task.taskNum]
        const error = StudyTask.hasErrors()
        if(error){
          const clickTestQuestionError = StudyTask.clickTestQuestionError
          clickTestQuestionError ? errors.push({ taskNum: task.taskNum, clickTestQuestionError }) : errors.push({ taskNum: task.taskNum })
          }
      }
      return errors
    },
    openQuestionModal(index: any){
      if(index != null) this.taskIndexToAddQuestion = index
      this.isModalOpen = !this.isModalOpen
    },
    openCheckpointModal(index: any){
      if(index != null) this.taskIndexToAddCheckpoint = index
      this.isModalCheckpointOpen = !this.isModalCheckpointOpen
    },
    createCheckpoint(checkpoint: any){
      const task: any = this.tasks[this.taskIndexToAddCheckpoint]
      task.uxData.checkpoints.push(checkpoint)
      this.$emit('data-updated')
    },
    createQuestion(type: any){
      const task: any = this.tasks[this.taskIndexToAddQuestion]
      createQuestion(task.questions, type)
      this.$emit('data-updated')
    },
    updateTask(index:any, updatedTask:any) {
      const updatedTasks = [...this.tasks];
      updatedTasks[index] = updatedTask;
      this.$emit('update:tasks', updatedTasks);
      this.$emit('data-updated');
    },
    // Función que actualiza las reglas de navegación (strings) usando un mapeo de taskNum antiguo -> nuevo
    updateNavigationRulesObjects(rules: any[], taskMapping: { [oldTaskNum: number]: number }): any[] {
      return rules.map(rule => {
        // Actualizar cada condición
        if (rule.conditions && Array.isArray(rule.conditions)) {
          rule.conditions = rule.conditions.map((condition:any) => {
            if (condition.taskNum) {
              const oldNum = parseInt(condition.taskNum);
              if (!isNaN(oldNum) && taskMapping[oldNum] !== undefined) {
                condition.taskNum = taskMapping[oldNum].toString();
              }
            }
            return condition;
          });
        }
        // Actualizar la acción, si tiene un taskNum definido (no vacío)
        if (rule.action && rule.action.taskNum) {
          const oldNum = parseInt(rule.action.taskNum);
          if (!isNaN(oldNum) && taskMapping[oldNum] !== undefined) {
            rule.action.taskNum = taskMapping[oldNum].toString();
          }
        }
        return rule;
      });
    }
,

    // Métodos para drag & drop nativo
    dragStart(index: number) {
      this.dragStartIndex = index;
    },
    dragEnter(e: DragEvent, index: number) {
      this.dragOverIndex = index;
    },
    drop(e: DragEvent, index: number) {
      e.preventDefault();
      if (this.dragStartIndex !== null && index !== this.dragStartIndex) {
        const tasksArr = this.tasks as any[];
        const oldMappingById: { [id: string]: number } = {};
        tasksArr.forEach(task => {
          oldMappingById[task.identifier] = task.taskNum;
        });

        const activeTaskIdentifier = tasksArr[this.dragStartIndex].identifier;

        const movedItem = tasksArr[this.dragStartIndex];
        const updatedTasks = [...tasksArr];
        updatedTasks.splice(this.dragStartIndex, 1);
        updatedTasks.splice(index, 0, movedItem);

        updatedTasks.forEach((task: any, i: number) => {
          task.taskNum = i + 1;
        });

        const taskMapping: { [oldTaskNum: number]: number } = {};
        updatedTasks.forEach(task => {
          const oldNum = oldMappingById[task.identifier];
          taskMapping[oldNum] = task.taskNum;
        });

        const updatedRules = this.updateNavigationRulesObjects(this.navigationRules, taskMapping);
        this.$emit('update:navigationRules', updatedRules);

        this.$emit('update:tasks', updatedTasks);
        this.$emit('data-updated');

        const newActiveIndex = updatedTasks.findIndex(
          (task: any) => task.identifier === activeTaskIdentifier
        );
        if (newActiveIndex !== -1) {
          this.selectTask(newActiveIndex);
        }
      }
      this.dragOverIndex = null;
      this.dragStartIndex = null;
    },
    dragEnd() {
      this.dragOverIndex = null;
      this.dragStartIndex = null;
    },
    isWatNeuro() {
      return this.type != "" && this.type == "WAT_NEURO"
    }
  },
  watch: {
    tasks: {
      handler(newTasks, oldTasks) {
        if (newTasks.length > oldTasks.length) {
          this.activeTaskIndex = newTasks.length - 1;
        }
      },
      deep: true,
    },
  },
})
