import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb255fbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "StudyUXTasks" }
const _hoisted_2 = { class: "StudyUXTasks--Tabs" }
const _hoisted_3 = ["onDragstart", "onDragenter", "onDrop"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudyTab = _resolveComponent("StudyTab")!
  const _component_StudyTask = _resolveComponent("StudyTask")!
  const _component_FigmaIframeModal = _resolveComponent("FigmaIframeModal")!
  const _component_AddQuestionModal = _resolveComponent("AddQuestionModal")!
  const _component_AddCheckpointModal = _resolveComponent("AddCheckpointModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (t, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: t.identifier,
          class: _normalizeClass(["DraggableTab", { 'is-dragover': index === _ctx.dragOverIndex }]),
          draggable: "true",
          onDragstart: ($event: any) => (_ctx.dragStart(index)),
          onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
          onDragenter: ($event: any) => (_ctx.dragEnter($event, index)),
          onDrop: ($event: any) => (_ctx.drop($event, index)),
          onDragend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragEnd && _ctx.dragEnd(...args)))
        }, [
          _createVNode(_component_StudyTab, {
            class: "study-tab",
            num: t.taskNum,
            text: t.name || _ctx.$t('suite_study_task_name'),
            active: t.taskNum == _ctx.activeTaskNum,
            onClick: ($event: any) => (_ctx.selectTask(index, t.taskNum)),
            showMenuDots: !_ctx.isWatNeuro(),
            onRemoveTask: ($event: any) => (_ctx.removeTask(index)),
            onDuplicateTask: ($event: any) => (_ctx.$emit('duplicate-task', t)),
            isUx: ""
          }, null, 8, ["num", "text", "active", "onClick", "showMenuDots", "onRemoveTask", "onDuplicateTask"])
        ], 42, _hoisted_3))
      }), 128)),
      (!_ctx.isWatNeuro())
        ? (_openBlock(), _createBlock(_component_StudyTab, {
            key: 0,
            class: "study-tab",
            text: _ctx.$t('suite_study_task_add'),
            showAddIcon: true,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('add-task'))),
            style: {"cursor":"pointer"}
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (t, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_StudyTask, {
        ref: 'StudyTask-'+t.taskNum,
        key: t.identifier,
        task: t,
        navigationRules: _ctx.navigationRules,
        type: _ctx.type,
        isUxTest: true,
        onUpdateTask: ($event: any) => (_ctx.updateTask(index, $event)),
        onOpenIframe: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openIframe($event))),
        onDataUpdated: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('data-updated'))),
        onOpenCheckpointsModal: ($event: any) => (_ctx.openCheckpointModal(index)),
        onOpenQuestionModal: ($event: any) => (_ctx.openQuestionModal(index))
      }, null, 8, ["task", "navigationRules", "type", "onUpdateTask", "onOpenCheckpointsModal", "onOpenQuestionModal"])), [
        [_vShow, t.taskNum == _ctx.activeTaskNum]
      ])
    }), 128)),
    (_ctx.showIframe)
      ? (_openBlock(), _createBlock(_component_FigmaIframeModal, {
          key: 0,
          url: _ctx.iframeUrl,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showIframe = false))
        }, null, 8, ["url"]))
      : _createCommentVNode("", true),
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_AddQuestionModal, {
          key: 1,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openQuestionModal(null))),
          onSelectQuestion: _cache[7] || (_cache[7] = ($event: any) => (_ctx.createQuestion($event)))
        }))
      : _createCommentVNode("", true),
    (_ctx.isModalCheckpointOpen)
      ? (_openBlock(), _createBlock(_component_AddCheckpointModal, {
          key: 2,
          onAddCheckpoint: _cache[8] || (_cache[8] = ($event: any) => (_ctx.createCheckpoint($event))),
          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openCheckpointModal(null)))
        }))
      : _createCommentVNode("", true)
  ]))
}